import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import Navbar from '../Navbar'

export default function Header() {

    const [isActive, setActive] = useState(false);
    const ToggleClass = () => {
        setActive(!isActive); 
    };

    return (
        <header className={`site-header ${isActive ? 'site-header--active' : ''}`}>

            <NavLink to="/">
                <span className="site-header__logo h3">
                    <span>H</span><span className="site-header__left">assan</span><span>N</span><span className="site-header__right">ur</span>
                </span>
            </NavLink>

            <Navbar />

            <button className="menu-toggle" onClick={ToggleClass}>
                <svg className="menu-toggle__burger" viewBox="0 0 20 20"><path d="M16.4 9h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM16.4 13h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1zM3.6 7h12.8c0.552 0 0.6-0.447 0.6-1s-0.048-1-0.6-1h-12.8c-0.552 0-0.6 0.447-0.6 1s0.048 1 0.6 1z"></path></svg>
                <svg className="menu-toggle__close" viewBox="0 0 20 20"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </button>
        </header>
    )
}