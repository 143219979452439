import React, { useEffect, useState } from 'react'
import sanityClient from '../client'
import { PortableText } from '@portabletext/react'
import SkillsList from './blocks/SkillsList'

export default function About() {

    const [aboutContent, setAboutContent] = useState(null)

    useEffect(() => {
        sanityClient.fetch(`
            *[_type == "about"] {
                title,
                slug,
                body,
                profileText,
                skillsList
            }
        `)
            .then((data) => setAboutContent(data[0]))
            .catch(console.error)
    }, [])

    console.log(aboutContent)


    if (!aboutContent) return <div className = "lds-dual-ring" > < /div>

    return (

        <
        main >

        <
        h1 className = "single-project__header" > { aboutContent.title } < /h1>

        <
        div className = "body-content" >
        <
        PortableText value = { aboutContent.profileText }
        />

        <
        SkillsList skillsList = { aboutContent.skillsList }
        />

        <
        /div>

        <
        /main>
    )
}