import React from 'react'
import {NavLink} from 'react-router-dom'

export default function Navbar() {

    return (
        <nav className="site-header__nav">
            <NavLink to="/about" activeClassName="active">
                About
            </NavLink>
            <NavLink to="/project" activeClassName="active">
                Projects
            </NavLink>
            <NavLink to="/contact" activeClassName="active">
                Contact
            </NavLink>
        </nav>
    )
}