import React, {useState, useEffect} from 'react'
import SocialMedia from '../blocks/SocialMedia'

export default function Footer() {

    function display() {
        var date = new Date();
        var res = date.getFullYear();
        return res
    }

    return (
        <footer className="site-footer">

            <p>
                &copy; Hassan Nur <span id="year">{display()}</span>
            </p>

            <SocialMedia />
        </footer>
    )
}