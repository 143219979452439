import React, {useState} from 'react'
import sanityClient from '../../client'
import imageUrlBuilder from '@sanity/image-url'
import Masonry from 'react-masonry-css'
import FsLightbox from 'fslightbox-react'


export default function SingleProjectImageGrid(props) {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    const gridName = 'project-image-grid'

    const breakpointColumnsObj = {
        default: 4,
        1200: 3,
        1028: 2,
        768: 1
    };

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
        return builder.image(source)
    }

    let gallerySrcArray = []

    props.images.map((image, index) => (
        gallerySrcArray[index] = urlFor(image).url()
    ))

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number,
        });
    }

    return (
        <section className={gridName}>
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className={`${gridName}__wrapper`}
                columnClassName={`${gridName}__column`}
            >
                {props.images.map((image, index) => (
                    
                    <div className={`${gridName}__item`} key={image._key}>
                        <picture onClick={() => openLightboxOnSlide(index+1)}>
                            <img src={urlFor(image).url()} />
                        </picture>
                    </div>
                ))}
            </Masonry>

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={gallerySrcArray}
                slide={lightboxController.slide}
            />

        </section>
    )
}