import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import sanityClient from '../client'

export default function Project() {

    const [postData, setPost] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == 'project']{
                title,
                slug,
                thumbnailImage {
                    asset-> {
                        _id,
                        url
                    },
                    alt
                }
            }`)
            .then((data) => setPost(data))
            .catch(console.error)
    }, []);

    if (!postData) return <div className="lds-dual-ring"></div>

    console.log(postData)

    return (
        <main>
            <section>
                <h1 className="single-project__header">Projects</h1>
                <div className="project-grid">
                    {postData && postData.map((project, index) => (
                        <article key={project.slug.current}>
                            <Link to={"/project/" + project.slug.current} >
                                <section>
                                    <picture>
                                        <img src={project.thumbnailImage.asset.url} alt={project.thumbnailImage.alt} />
                                    </picture>
                                    <h1>{project.title}</h1>
                                </section>
                            </Link>
                        </article>
                    ))}
                </div>
            </section>
        </main>
    )
}