import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import SingleProjectImageGrid from './blocks/SingleProjectImageGrid';
import sanityClient from '../client'

export default function SingleProject() {
    
    const [singleProject, setSingleProject] = useState(null);
    const {slug} = useParams()

    useEffect(() => {
        sanityClient
            .fetch(`*[slug.current == "${slug}"]{
                title,
                _id,
                slug,
                thumbnailImage {
                    asset-> {
                        _id,
                        url
                    },
                    alt
                },
                images,
                highlightVideo
            }`)
            .then(
                (data) => setSingleProject(data[0])
            )
            .catch(console.error)
    }, [slug]);

    if (!singleProject) return <div class="lds-dual-ring"></div>

    return (
        <main className="single-project">
            <header>

                <h1 className="single-project__header">{singleProject.title}</h1>

            </header>

            <section className="single-project__video">
                
                <iframe id="ytplayer" type="text/html"
                        src={`https://www.youtube.com/embed/${singleProject.highlightVideo}`}
                        frameBorder="0">
                </iframe>

            </section>

            <SingleProjectImageGrid images={singleProject.images} />

        </main>
    )
}