import React, {useEffect, useState} from 'react'
import sanityClient from '../client'
import {PortableText} from '@portabletext/react'

export default function Contact() {

    const [contactContent, setContactContent] = useState(null)

    useEffect(() => {
        sanityClient.fetch(`
            *[_type == "contact"] {
                title,
                slug,
                body
            }
        `)
        .then((data) => setContactContent(data[0]))
        .catch(console.error)
    }, [])

    if (!contactContent) return <div className="lds-dual-ring"></div>

    return (
        
        <main>
            <h1 className="single-project__header">{contactContent.title}</h1>

            <div className="body-content">
                <PortableText
                    value={contactContent.body}
                />
            </div>

        </main>

    )
}