import React, { useState, useEffect } from 'react'
import sanityClient from '../client'
import VideoBackground from './blocks/VideoBackground'
import VideoModal from './blocks/VideoModal'

export default function Home() {
    const [homeContent, setHomeContent] = useState(null)

    useEffect(() => {
        sanityClient.fetch(`
            *[_type == "home"] {
                title,
                subtitle,
                youtubeId,
                showreel,
                introText
            }
        `)
        .then((data) => setHomeContent(data[0]))
        .catch(console.error)
    }, [])

    if (!homeContent) return <div className="lds-dual-ring"></div>

    return (
        
        <main>

            <div className="video-overlay">
                <h1 className="h1">{homeContent.title}</h1>
                <h2 className="h3">{homeContent.subtitle}</h2>
                <VideoModal id={homeContent.showreel} />   
            </div>

            <VideoBackground id={homeContent.youtubeId} />

        </main>
    )
}