import React from 'react'

export default function VideoBackground(props) {


    return (
        <div className="video-background">
            <figure className="video-background__container">
            <iframe id="ytplayer" type="text/html"
                src={`https://www.youtube.com/embed/${props.id}?autoplay=1&mute=1&loop=1&controls=0&playlist=${props.id}`}
                frameBorder="0"></iframe>
            </figure>
        </div>
    )
}