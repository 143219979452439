import React, {useState, useEffect} from 'react'
import sanityClient from '../../client'
import imageUrlBuilder from '@sanity/image-url'

export default function SocialMedia(props) {

    const [socialContent, setSocialMedia] = useState(null)

    useEffect(() => {
        sanityClient.fetch(`
            *[_type == "globals"] {
                socialMedia
            }
        `)
        .then((data) => setSocialMedia(data[0]))
        .catch(console.error)
    }, [])

    const builder = imageUrlBuilder(sanityClient)

    function urlFor(source) {
        return builder.image(source)
    }


    if (!socialContent) return <div className="lds-dual-ring"></div>


    return (
        <ul className="social-media">
            {socialContent.socialMedia.map(social => (
                <li className="social-media__item">
                    <a href={`//${social.socialUrl}`} target="_blank">
                        <img src={urlFor(social.socialLogo)} />
                    </a>
                </li>
            ))}
        </ul>
    )
}