import React, {useState, useEffect} from 'react'
import Button from '../blocks/Button'

export default function VideoModal(props) {

    const [showModal, setShowModal] = useState(false)

    const launch = () => {
        setShowModal(true)
        document.body.classList.add('video-modal--open')
    }

    const close = () => {

        document.body.classList.remove('video-modal--open')
        setShowModal(false)
    }

    return (

        <div>

            <Button href="#/" method={launch} title="Play showreel" />

            {showModal ? 
                <div class="video-modal">
                    <svg className="video-modal__close" viewBox="0 0 20 20"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" onClick={close}></path></svg>
                    <iframe id="ytplayer" type="text/html"
                        src={`https://www.youtube.com/embed/${props.id}?autoplay=1&playlist=${props.id}`}
                        frameBorder="0">
                    </iframe>
                </div>
            : ''}

            

        </div>
    )

}