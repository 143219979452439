import {BrowserRouter, Routes, Route} from "react-router-dom"
import Home from './components/Home'
import About from './components/About'
import SingleProject from './components/SingleProject'
import Project from './components/Project'
import Contact from './components/Contact'
import Header from './components/globals/Header'
import Footer from './components/globals/Footer'
import './App.scss'

function App() {

    document.title = "Hassan Nur | 3D Digital Artist"

    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<About />} path="/about" />
                <Route element={<SingleProject />} path="/project/:slug" />
                <Route element={<Project />} path="/project" />
                <Route element={<Contact />} path="/contact" />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;